import React from "react"

const IPBLNosaci = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>
          Proizvodna dužina: 6 m, 12 m. Po zahtevu moguće je isporučiti veće
          dimenzije i sečenje na meru.
        </p>
      </div>
      <div className="table-scroll">
        <table class="table is-bordered is-hoverable">
          <tbody>
            <tr className="has-background-link has-text-white">
              <td rowSpan={2}>
                <br />
                Oznaka UPN
              </td>
              <td colSpan={5}>Mere (mm)</td>
              <td rowSpan={2}>
                <br />
                Masa (kg)
              </td>
            </tr>
            <tr>
              <td>h</td>
              <td>b</td>
              <td>s</td>
              <td>t</td>
              <td>r</td>
            </tr>
            <tr>
              <td>100</td>
              <td>96</td>
              <td>100</td>
              <td>5,0</td>
              <td>8,0</td>
              <td>12,0</td>
              <td>16,70</td>
            </tr>
            <tr>
              <td>120</td>
              <td>114</td>
              <td>120</td>
              <td>5,0</td>
              <td>8,0</td>
              <td>12,0</td>
              <td>19,90</td>
            </tr>
            <tr>
              <td>140</td>
              <td>133</td>
              <td>140</td>
              <td>5,5</td>
              <td>8,5</td>
              <td>12,0</td>
              <td>24,70</td>
            </tr>
            <tr>
              <td>160</td>
              <td>152</td>
              <td>160</td>
              <td>6,0</td>
              <td>9,0</td>
              <td>15,0</td>
              <td>30,40</td>
            </tr>
            <tr>
              <td>180</td>
              <td>171</td>
              <td>180</td>
              <td>6,0</td>
              <td>9,5</td>
              <td>15,0</td>
              <td>35,50</td>
            </tr>
            <tr>
              <td>200</td>
              <td>190</td>
              <td>200</td>
              <td>6,5</td>
              <td>10,0</td>
              <td>18,0</td>
              <td>42,30</td>
            </tr>
            <tr>
              <td>220</td>
              <td>210</td>
              <td>220</td>
              <td>7,0</td>
              <td>11,0</td>
              <td>18,0</td>
              <td>50,50</td>
            </tr>
            <tr>
              <td>240</td>
              <td>230</td>
              <td>240</td>
              <td>7,5</td>
              <td>12,0</td>
              <td>21,0</td>
              <td>60,30</td>
            </tr>
            <tr>
              <td>260</td>
              <td>250</td>
              <td>260</td>
              <td>7,5</td>
              <td>12,5</td>
              <td>24,0</td>
              <td>68,20</td>
            </tr>
            <tr>
              <td>280</td>
              <td>270</td>
              <td>280</td>
              <td>8,0</td>
              <td>13,0</td>
              <td>24,0</td>
              <td>76,40</td>
            </tr>
            <tr>
              <td>300</td>
              <td>290</td>
              <td>300</td>
              <td>8,5</td>
              <td>14,0</td>
              <td>27,0</td>
              <td>88,30</td>
            </tr>
            <tr>
              <td>320</td>
              <td>310</td>
              <td>300</td>
              <td>9,0</td>
              <td>15,5</td>
              <td>27,0</td>
              <td>97,60</td>
            </tr>
            <tr>
              <td>340</td>
              <td>330</td>
              <td>300</td>
              <td>9,5</td>
              <td>16,5</td>
              <td>27,0</td>
              <td>105,00</td>
            </tr>
            <tr>
              <td>360</td>
              <td>350</td>
              <td>300</td>
              <td>10,0</td>
              <td>17,0</td>
              <td>27,0</td>
              <td>112,00</td>
            </tr>
            <tr>
              <td>400</td>
              <td>390</td>
              <td>300</td>
              <td>11,0</td>
              <td>19,0</td>
              <td>27,0</td>
              <td>125,00</td>
            </tr>
            <tr>
              <td>450</td>
              <td>440</td>
              <td>300</td>
              <td>11,5</td>
              <td>21,0</td>
              <td>27,0</td>
              <td>140,00</td>
            </tr>
            <tr>
              <td>500</td>
              <td>490</td>
              <td>300</td>
              <td>12,0</td>
              <td>23,0</td>
              <td>27,0</td>
              <td>155,00</td>
            </tr>
            <tr>
              <td>550</td>
              <td>540</td>
              <td>300</td>
              <td>12,5</td>
              <td>24,0</td>
              <td>27,0</td>
              <td>166,00</td>
            </tr>
            <tr>
              <td>600</td>
              <td>590</td>
              <td>300</td>
              <td>13</td>
              <td>25,0</td>
              <td>27,0</td>
              <td>178,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default IPBLNosaci
